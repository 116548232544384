@import url("../../assets/variables.css");
.notfound-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url('../../assets/errorpage.png') center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayoverlaynotfound {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  max-width: 600px;
  color: white;
  padding: 20px;
  text-align: left;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.error-code {
  color: var(--primary-color);
}

h3 {
  margin-bottom: 15px;
}

h5 {
  margin: 15px 0;
  font-size: 15px;
}

ul {
  margin: 10px 0 20px 20px;
}

li {
  margin-bottom: 10px;
}

.action-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 30em;
}

.action-button:hover {
  background-color: darkgray;
}

@media (max-width: 1024px) {
  .content {
    max-width: 80%;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h5 {
    font-size: 1rem;
  }

  .action-button {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .notfound-container {
    height: 100%;
    background-size:auto;
  }

  .content {
    padding: 10px;
    max-width: 90%;
    text-align: left;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 0.9rem;
    margin: 10px 0;
  }

  ul {
    margin: 10px auto;
    padding-left: 0;
  }

  li {
    margin-bottom: 8px;
  }

  .action-button {
    width: 100%;
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}



