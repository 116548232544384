@import url("../../assets/variables.css");
.services-container {
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  .services-header {
    align-self: flex-start;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    margin-bottom: 1.5rem;
  }
  
  .services-images-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    overflow-x: hidden;
  }
  
  .service-itemMain {
    width: 100%; /* Увеличили ширину изображений */
  }
  .service-item-main{
    position: relative;
  }
  .goToMore{
    position: absolute;
    bottom: 2px;
    right: 5px;
    display: flex;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
  }

  .goToMore p{
    padding: 12px;
    font-size: 18px;
  }
  .request-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #1D1D1B;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .request-button:hover{
    background-color: var(--primary-color);
  }
@media (max-width: 768px) {
  .services-images-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  .service-itemMain {
    width: 100% !important;
  }
}
@media (max-width: 425px) {
  .services-images-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }

  .service-itemMain {
    width: auto;
  }
}
@media (max-width: 1024px){
  .service-itemMain {
    width: 100%;
    height: auto;
  }
}
  