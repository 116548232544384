@import url("../../assets/variables.css");
.preloader {
    position: fixed;
    top: 0;
    right: 0; /* Начинаем с правой стороны */
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: #1D1D1B;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.7s ease;
    transform: translateX(100%);
}

.preloader--show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0); /* Появление с правой стороны */
}

.preloader--hide {
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%); /* Сдвиг влево при скрытии */
}

.preloader__content {
    text-align: center;
}

.preloader__text {
    font-size: 24px;
    color: #007bff;
}

.loader {
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 50%;
    color: var(--primary-color);
    animation: fill 1s ease-in infinite alternate;
}

.loader::before, .loader::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    left: 48px;
    top: 0;
    animation: fill 0.9s ease-in infinite alternate;
}

.loader::after {
    left: auto;
    right: 48px;
    animation-duration: 1.1s;
}

@keyframes fill {
    0% { box-shadow: 0 0 0 2px inset; }
    100% { box-shadow: 0 0 0 10px inset; }
}
