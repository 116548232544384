@import "../../assets/fonts/fonts.css";
@import url("../../assets/variables.css");
.about-us-main {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 2rem;
    background-color: #FFFFFF;
}
.about-us-main-infoBlock{
  display: flex;
  height: 100%;
}

.header-text {
    position: absolute;
    top: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    font-family: Stapel;
}

.left-image-container {
    position: relative;
    margin-top: 3rem;
    flex: 2;
    width: 100%;
}

.main-image {
    width: 100%;
    height: 100%;
}

.more-icon {
    position: absolute;
    bottom: 0.7rem;
    right: 1.0rem;
    width: 2rem;
    height: auto;
}

.right-text-container {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-top: 48px;
    flex: 1;
    max-width: 100%;
    height: 100%;
}

.text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-size: 1.1rem;
    line-height: 1.5;
    height: 300px;
}
.black-text{
  border-radius: 0 8px  0 0;
}
.orange-text{
  border-radius: 0 0 8px 0;
}

.black-text {
    color: white;
    background-color: black;
    margin-bottom: 0;
}

.orange-text {
    color: white;
    background-color: var(--primary-color);
    margin-top: 0;
}

@media (max-width: 900px) {
    .about-us-main {
        flex-direction: column;
        padding: 15px;
    }
    .about-us-main-infoBlock{
      flex-direction: column;
    }

    .right-text-container {
        margin-top: 0;
        flex-direction: row;
    }

    .text-box {
        padding: 20px;
        font-size: 12px;
        align-items: center;
        justify-content: center;
    }

    .left-image-container {
        margin-bottom: 0;
        border-radius: 8px 8px 0 0;
    }

    .main-image {
        border-radius: 8px 8px 0 0;
    }

    .black-text {
        border-radius: 0 0 0 8px;
    }

    .orange-text {
        border-radius: 0 0 8px 0;
    }
}

@media (max-width: 340px) {
    .text-box {
        height: auto;
    }

}

  