/* Основной стиль */
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url("../../assets/variables.css");
.main-page {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/main img.png');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.center-text{
  width: 70%;
}
.vertical-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: rgba(218, 218, 218, 1);
}

.line1 {
  left: 3.5%;
}

.line2 {
  left: 50%;
}

.line3 {
  right: 3.5%;
}

.horizontal-line {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: rgba(218, 218, 218, 1);
}

.horizontal-line.line1 {
  top: 14%;
}

.horizontal-line.line2 {
  top: 55%;
}

.horizontal-line.line3 {
  bottom: 5%;
}

.top-text {
  font-family: Stapel;
  position: absolute;
  top: 14%;
  left: 3.5%;
  width: calc(50% - 3.5%);
  height: calc(55% - 14%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  border-radius: 8px;
}

.bottom-text {
  position: absolute;
  font-size: 1.5rem;
  top: 55%;
  left: 3.5%;
  width: calc(50% - 3.5%);
  height: calc(95% - 55%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  border-radius: 8px;
}

.center-square {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.263);
  top: 55%;
  left: 50%;
  width: calc(50% - 3.5%);
  height: calc(95% - 55%);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: white;
}

.form-container {
  display: flex;
  gap: 1rem;
}
.form-container-h2{
  font-size: 24px;
  margin: -15px 28px 10px -1px;
}

.form-left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-right {
  flex: 1;
}

.input-field, .textarea-field {
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border: none;
  border-bottom: 1px solid gray;
  border-radius: 4px;
  margin-bottom: 0.8rem;
}

.textarea-field {
  width: 100%;
  height: 89%;
  resize: none;
}

.input-field::placeholder, .textarea-field::placeholder {
  color: gray;
}

.submit-button {
  margin-top: 1rem;
  padding: 0.75rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  height: 40px;
}

.agreement-mobile{
  font-size: 13px;
}

.policy-link {
  color: var(--primary-color);
  margin-left: 0.3rem;
}

.icon-container {
  position: absolute;
  top: 40%;
  right: 1%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.social-icon {
  color: rgba(135, 135, 135, 1);
  font-size: 2rem;
  cursor: pointer;
  transition: transform 0.3s;
}

.social-icon:hover {
  transform: scale(1.1);
}
.center-square-mobile{
  display: none;
}

@media (max-width: 1024px){
  .icon-container {
    position: absolute;
    top: 40%;
    right: 0.3%;
    /* transform: translateY(-50%); */
    display: none;
    flex-direction: column;
    gap: 4rem;
  }
}

@media (max-width: 768px) {
  .main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
  .vertical-line.line1{
    display: none;
  }
  .top-text{
    background-color: rgba(51, 50, 50, 0.99);
    left: 0;
    width: 50%;
    padding: 40px;
    height: 55%;
    text-align: left;
    top: 0;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    border-radius: 0;
  }
  .center-text{
    font-size: 20px;
  }
  .bottom-text{
    background-color: var(--primary-color);
    left: 0;
    border-radius: 0;
    padding: 50px;
    height: 45%;
    width: 50%;
    text-align: left;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .center-square {
    display: none;
  }

  .icon-container {
    flex-direction: row;
    gap: 5rem;
    margin-bottom: 1rem;
    top: 44.4rem;
    width: 20rem;
    left: 16rem;
  }
  .center-square-mobile {
    display: block;
    position: static;
    width: 100%;
    background-color: rgba(29, 29, 27, 0.5);
    padding: 1rem;
  }
  .h2-mobile{
    color: white;
    font-size: 18px;
    font-family: Stapel;
    text-align: center;
  }
  .submit-button-mobile {
    width: 100%;
  }
  .form-container-mobile {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .form-left-mobile {
    width: 100%;
  }
  .horizontal-line.line2{
    display: none;
  }
  .horizontal-line.line3{
    display: none;
  }
  /* .form-right-mobile {
    flex: 1;
  } */

  .input-field-mobile, .textarea-field-mobile {
    padding: 0.75rem;
    background-color: rgba(0, 0, 0, 0.401);
    color: white;
    border: none;
    font-family: Inter;
    border-bottom: 1px solid gray;
    border-radius: 4px;
    margin-bottom: 1rem;
    width: 100%;
  }

  .textarea-field-mobile {
    width: 100%;
    height: 89%;
    resize: none;
  }

  .input-field-mobile::placeholder, .textarea-field-mobile::placeholder {
    color: gray;
  }

  .submit-button-mobile {
    margin-top: 1rem;
    padding: 0.75rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  .policy-link-mobile {
    color: var(--primary-color);
    margin-left: 0.3rem;
  }
  .agreement-mobile label{
    color: #FFFFFF
  }
}
@media (max-width: 426px) {
  .icon-container{
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 1rem;
    top: 44.4rem;
    width: 9rem;
    left: 5.5rem;
  }
  .bottom-text{
    font-size: 1rem;
  }
  .vertical-line.line1, .vertical-line.line3{
    display: none;;
  }
}
@media (min-width: 425px) and (max-width: 768px) {
  .icon-container {
    flex-direction: row;
    gap: 3rem;
    margin-bottom: 1rem;
    top: 44.4rem;
    width: 10rem;
    left: 8.5rem;
  }

}

