
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url("../../assets/variables.css");
.vakancies-page {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/vakancies.png');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.vakancies-page *{
  font-family: Stapel;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.226);
}

.vakancies-vertical-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: rgba(218, 218, 218, 1);
}

.line2 {
  left: 50%;
}

.line3 {
  right: 3.5%;
}

.vakancies-horizontal-line {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: rgba(218, 218, 218, 1);
}

.vakancies-horizontal-line.line1 {
  top: 14%;
}

.vakancies-horizontal-line.line22 {
  top: 55% !important;
}

.vakancies-horizontal-line.line2-3{
  display: none;
}

.vakancies-horizontal-line.line3 {
  bottom: 5%;
}

.vakancies-top-section {
  position: absolute;
  top: 55%;
  left: 3.5%;
  width: calc(59% - 3.5%);
  height: calc(95% - 14%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  color: rgb(255, 255, 255);
  padding: 1rem;
  border-radius: 8px;
  z-index: 2;
  overflow-y: hidden;
}




.vakancies-center-square {
  position: absolute;
  top: 55%;
  left: 50%;
  width: calc(50% - 3.5%);
  height: calc(95% - 55%);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: white;

}


.guarantee-title {
  text-align: start;
  font-size: 25px;
  color: white;
  margin: -13 0 8px 45px !important;
  z-index: 2;
}

.vakancies-bottom-section {
  position: absolute;
  top: 60%;
  left: 3.5%;
  width: calc(50% - 3.5%);
  z-index: 2;
}


.vakancies-rectangles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.3rem;
}

.vakancies-rectangles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.vakancies-rectangle {
  background-color: transparent;
  color: white;
  padding: 0.3rem;
  border: 1px solid #ccc;
  width: calc(42% - 0.3rem);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  min-height: 80px;
}


.vakancies-rectangle h6 {
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
}

.rectangle-content p {
  font-size: 0.6rem;
  color: #ccc;
  margin: 0;
}

.rectangle-icon {
  font-size: 0.8rem;
  color: #ccc;
}

.rectangle-content {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.invite-rectangle {
  background-color: transparent;
  border: 2px solid #ccc;
  padding: 20px;
  margin: 55px auto;
  max-width: 600px;
  text-align: center;
  font-size: 1rem;
  color: #333;
}
.invite-rectangleP{
  color: #fff;
}

.vakancies-partner-program {
  position: absolute;
  top: 25%; /* Начало первой горизонтальной линии */
  left: 41%; /* Отступ от второй вертикальной линии */
  width: calc(50% - 3.5%); /* Расстояние между второй и третьей вертикальными линиями */
  height: calc(55% - 14%); /* Расстояние между первой и второй горизонтальными линиями */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: 3rem;
  font-weight: bold;
  color: white;
  padding: 1rem;
  border-radius: 8px;
  z-index: 2; /* Чтобы заголовок был поверх других элементов */
}

.vakancies-open-jobs {
  background-color: #fff;
  padding: 2rem;
  margin-top: 2rem;
}

.vakancies-section-title {
  text-align: start;
  color: #333;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.vakancies-job-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.vakancies-job-card {
  background-color: #1D1D1B;
  color: #fff;
  width: 210px;
  height: 450px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 7px;
}

.vakancies-job-date {
  font-size: 0.8rem;
  color: #aaa;
}

.vakancies-job-title {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.vakancies-job-description {
  font-size: 0.9rem;
  color: #ccc;
  margin-bottom: auto;
}

.vakancies-apply-button {
  background-color: #777;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  align-self: center;
}

.vakancies-load-more {
  width: 100%;
  padding: 1rem;
  background-color: #777;
  color: #fff;
  border: none;
  border-radius: 4px;
  margin-top: 2rem;
  cursor: pointer;
  text-align: center;
}



.partnership-main {
  background-color: #1D1D1B;
  color: white;
  padding: 2rem;
}

.partnership-header {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: left;
}

.partnership-content {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.vakancies-tables-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.vakancies-table {
  border: 2px solid #888;
  padding: 1rem 0;
  border-radius: 8px;
}
.vakancies-table h4{
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.vakancies-table-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  position: relative;
  height: 3.5rem;
}
.vakancies-table-row *{
  font-family: Inter;
}

.vakancies-step-number {
  font-weight: bold;
  color: var(--primary-color);
}

.vakancies-table-row:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #888;
}

.vakancies-form-container {
  flex: 1;
  border: 2px solid #888;
  padding: 1rem;
  border-radius: 8px;
}

.vakancies-form {
  display: flex;
  gap: 1rem;
}

.vakancies-form-left, .vakancies-form-right {
  flex: 1;
}

.vakancies-input-field, .vakancies-textarea-field {
  padding: 0.5rem;
  background-color: rgba(138, 138, 138, 0.2);
  color: white;
  border: none;
  border-bottom: 1px solid gray;
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;
}

.vakancies-textarea-field {
  height: 89%;
  resize: none;
}

.vakancies-policy-link {
  color: var(--primary-color);
  margin-left: 0.3rem;
}

.vakancies-submit-button {
  margin-top: 1rem;
  padding: 0.75rem;
  width: 100%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}
.vakancies-right-text-form-container{
  margin: 0 0 30px 0;
}
.vakancies-bottom-section-mobile{
  display: none;
}
.vakancies-garant-section{
  display: none;
}

@media (max-width: 768px) {
  .vakancies-table-row{
    height: auto;
  }
  .vakancies-garant-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    width: 100%;
    background-color: #878787;
  }
  .vakancies-garant-section .vakancies-rectangle{
    background-color: #1D1D1B66;
  }

  .vakancies-form-container{
    width: 100%;
  }
  h2{
    font-size: 18px;
  }
  p,span{
    font-size: 12px;
  }
  .vakancies-form{
    display: flex;
    flex-direction: column;
  }
  .vakancies-job-list{
    display: flex;
    flex-direction: row;
    padding: 5px;
  }

  .vakancies-open-jobs{
    padding: 5px;
    display: flex;
    flex-direction: column;
  }
  .vakancies-section-title{
    font-size: 18px;
    text-align: center;
  }
  .vakancies-bottom-section {
    display: none;
  }
  .invite-rectangle {
    background-color: transparent;
    border: none;
    padding: 20px;
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
    font-size: 1.5rem;
    color: #333;
    margin-top: 97px;
  }
  .vakancies-bottom-section-mobile{
    display: block;
    background-color: rgb(137, 137, 137);
    padding: 20px;
  }
  .vakancies-horizontal-line.line2-3{
    display: block;
    top: 64%
  }
  .vakancies-horizontal-line.line22 {
    top: 38% !important;
  }
  .vakancies-partner-program {
    position: absolute;
    top: 31%; /* Начало первой горизонтальной линии */
    left: 3%; /* Отступ от второй вертикальной линии */
    width: calc(50% - 3.5%); /* Расстояние между второй и третьей вертикальными линиями */
    height: calc(55% - 14%); /* Расстояние между первой и второй горизонтальными линиями */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: white;
    padding: 1rem;
    border-radius: 8px;
    z-index: 2; /* Чтобы заголовок был поверх других элементов */
  }
  .vakancies-top-text{
    position: absolute;
    top: 11%; /* Начало первой горизонтальной линии */
    left: 48%; /* Отступ от второй вертикальной линии */
    width: calc(50% - 3.5%); /* Расстояние между второй и третьей вертикальными линиями */
    height: calc(55% - 14%); /* Расстояние между первой и второй горизонтальными линиями */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    padding: 1rem;
    border-radius: 8px;
    z-index: 2; /* Чтобы заголовок был поверх других элементов */
  }
  .vakancies-center-square{
   top: 45%
  }
}
@media (max-width: 426px) {
  .rectangle-icon{
    width: 50px;
  }
  .invite-rectangle {
    background-color: transparent;
    border: none;
    margin: 0 auto;
    max-width: 620px;
    text-align: center;
    font-size: 1rem;
    color: #333;
    margin-top: 28px;
  }
  .vakancies-job-card{
    width: auto;
  }
  .vakancies-center-square{
    left: 40%;
  }
  .vakancies-garant-section .rectangle-content{
    display: flex;
    flex-direction: column;
  }
}