@import url("../../assets/variables.css");
.advantages-main {
    background-color: #1D1D1B;
    color: white;
    padding: 2rem;
    position: relative;
  }

  .advantages-header {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: white;
  }

  .advantages-content {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    position: relative;
    height: 500px;
    border: 1px solid #888;
    border-radius: 15px;
  }

  .advantages-left {
    flex: 2;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .advantages-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .advantages-image img {
    max-width: 90%;
    border-radius: 8px;
  }

  .advantages-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .advantage-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: transparent;
    border-top: 2px solid #888;
  }

  .advantage-text {
    flex: 1;
    margin-right: 1rem;
    font-size: 1rem;
  }

  .advantage-number {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--primary-color);
  }


@media (max-width: 1024px) {
  /*.advantage-item{*/
  /*  display: flex;*/
  /*  justify-content: space-between;*/
  /*  align-items: center;*/
  /*  padding: 0.8rem;*/
  /*  background-color: transparent;*/
  /*  border-top: 2px solid #888;*/
  /*}*/
  .advantages-content{
    flex-direction: column;
  }
  .advantages-left{
    border-right: 2px solid #888;
    border-bottom: 2px solid #888;
  }
  .advantage-text{
    font-size: 12px;
  }
  .advantages-right{
    border-left: 2px solid #888;
  }
  .advantages-image img {
    max-width: 110%;
    border-radius: 8px;
  }
  .advantages-content{
    height: 100%;
  }
}
@media (min-width: 1441px){
  .advantages-image img {
    max-width: 75%;
    border-radius: 8px;
  }
}