@import "./assets/fonts/fonts.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter";
}
.header-font{
  font-family: "Stapel";
}