@import url("../../assets/variables.css");
.contacts {
    background-color: #1D1D1B;
    color: white;
    padding: 2rem;
    font-family: Arial, sans-serif;
    width: 100%;
    box-sizing: border-box;
    padding-top: 110px;
}

.contacts-header {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: left; /* Заголовок выровнен по левому краю */
}

/* Основная сетка */
.contacts-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    align-items: flex-start; /* Выравнивание элементов по верхнему и левому краю */
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

/* Колонка 1: Сотрудничество и офис */
.contacts-cooperation {
    margin-bottom: 1.5rem;
}

.contacts-cooperation-text {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.contacts-office {
    font-size: 1.2rem;
    margin-top: 2rem;
    font-weight: bold;
}

.contacts-address-phone {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
    text-align: left;
}

.contacts-work-time {
    font-size: 1rem;
    color: rgb(200, 200, 200);
    text-align: left;
}

/* Колонка 2: Форма и кнопка */
.contacts-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start; /* Элементы формы по левому краю */
}

.contacts-form-text {
    font-size: 1rem;
    color: rgb(200, 200, 200);
    text-align: left;
}

.contacts-submit-button {
    background-color: rgb(100, 100, 100);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.contacts-submit-button:hover {
    background-color: rgb(150, 150, 150);
}

/* Колонка 3: Email и телефон */
.contacts-email {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start; /* Элементы email по левому краю */
}

.contacts-email-text {
    font-size: 1rem;
    color: grey;
    text-align: left;
}

.contacts-email-address,
.contacts-phone-number {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
}

.contacts-phone-number {
    margin-top: 1rem;
    text-align: left;
}

/* Карта */
.contacts-map {
    grid-column: 1 / 4; /* Растягиваем карту на всю ширину */
    margin-top: 2rem;
    width: 100%;
    height: 300px;
    background-color: #333;
}

/* Адаптивная версия */
@media (max-width: 1024px) {
    .contacts-content {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
    }

    .contacts-map {
        grid-column: 1 / 3;
    }
}

@media (max-width: 768px) {
    .contacts-content {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .contacts-header {
        font-size: 1.8rem;
    }

    .contacts-form,
    .contacts-email,
    .contacts-address-phone {
        align-items: flex-start; /* Выравнивание всех элементов по левому краю */
    }

    .contacts-submit-button {
        width: auto;
        max-width: 250px;
    }

    .contacts-map {
        grid-column: 1;
         /* Меньшая высота для мобильных устройств */
    }
}
