@import url("../../assets/variables.css");
.ServicesCont{
  padding-top: 100px;
}
.capital-works-title{
  width: 100%;
  background-color: #878787;
  border-radius: 8px;
  padding: 25px ;

}
.cleaning{
  background-color: #777777;
  border-radius: 8px;
  padding: 5px;
}
.cleaning h2{
  font-family: Stapel;
}
.capital-works-title p {
  font-family: Stapel;
  font-size: 40px;
  text-align: left;
}
.capital-works-btn{
  width: 100%;
  font-family: Stapel;
  background-color: #878787;
  border: 1px solid #DADADA;
  border-radius: 8px;
  font-size: 20px;
  color: #FFFFFF;
  margin-top: 10px;
  padding: 20px;
  transition: background-color 0.5s ease;
  cursor: pointer;

}
.capital-works-btn:hover{
  background-color: var(--primary-color);
}
.service-item-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.service-item-content-section{
  display: flex;
  flex-direction: column;
 width: 100%;
}
.transport-service-nav{
  background: #1D1D1B;
  padding: 5px 5px 0;
  border-radius: 10px;
  height: 44px;
}
.transport-service-tabs{
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  width: 100%;
}
.transport-service-tab{
  list-style: none;
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  border-radius: 5px;
  width: 100%;
  padding: 10px 15px;
  position: relative;
  background: #1D1D1B;
  cursor: pointer;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  min-width: 0;
  user-select: none;
  color: #878787;
}
#underline{
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background: #878787;
}
.transport-service-tab-content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-content{
  padding: 25px;
  display: flex;
}
.tab-content p{
  text-align: left;
  margin-left: 10px;
  justify-content: left;
  font-size: 32px;
  font-family: Stapel;
}
.tab-content-image{
  width: 70%;
  height: auto;
  border-radius: 8px;
}



.transport-service-content{
  display: flex;
  flex-direction: column;
  height: 400px;
}
.transport-service-content-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 250px;
  position: relative;
  margin-bottom: 5px;
}
.transport-service-appear-block{
  width: 1450px;
  height: 400px;
  position: absolute;
  top: 0;
  left: calc(100% + 10px);
  transform: translateY(-50%);
  background-color: lightblue;
  border-radius: 10px;
}
.transport-service-content-title{
  padding: 10px 20px;
  text-align: left;
  background: none;
  color: #fff;
  border: none;
  width: 200px;
  border-bottom: 1px solid ;
  cursor: pointer;
}






.add-services-list{
  padding: 20px;
}
.content-section-list{
  display: flex;
  flex-direction: column;

}
.service-item-content-section h3{
  text-align: left;
}
.content-section-list-element{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #878787;
  padding-bottom: 5px;
  color: #878787;
  margin-bottom: 5px;
  text-align: left;
}


.header-block{
  background-color: #1D1D1B;
  padding: 60px 30px;
}
.header-block h1{
  color: #FFFFFF;
  font-size: 80px;
}
.tarif-plan{
  background-color: #1D1D1B;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  padding: 20px;
  margin: 5px;
}
.tarif-plan-item{
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  text-align: left;
  align-items: center;
  border-bottom: 1px solid #878787;
}
.tarif-plan-advantages{
  padding: 15px;
}
.tarif-plan-advantages ul{
  text-align: left;
}


.tarifs-section{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.tarif-header{
  font-size: 40px;
  line-height: 50.6px;
  text-align: left;

}
.business-header{
  color: #FFFFFF;
}
.business-container{
  background-color: #878787;
}
.services-business-form{
  background-color: #1D1D1B;
  height: auto;
  padding: 25px 5px;
  margin: 25px;
  border-radius: 8px;
}
.services-business-form-header{
  text-align: left;
  font-family: Stapel;
  padding: 10px;
}
.services-business-form-underheader{
  font-family: Stapel;
  padding: 20px 80px 20px 50%;
  text-align: left;
  text-indent: 2em;
}
.services-business-form-leftBlock{
  text-align: left;
  width: 600px;
  padding: 0 20px;
  font-size: 24px;
}
.services-business-form-mainInfo{
  display: flex;
  width: 100%;
  padding: 15px;
  flex-direction: row;
  margin-bottom: 15px;
}
.services-business-form-mainInfo-rightBlock{
  display: flex;
  flex-direction: row;
}
.services-business-form-mainInfo-rightBlock p{
  width: 40%;
  margin-right: 50px;
  text-align: left;
  font-size: 18px;
}
.services-business-form-application{
  display: flex;
  flex-direction: row;
  padding: 15px;
}
.services-business-form-application-rightBlock{
  width: 100%;
}

.services-business-form-inputs{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.services-business-form-input, .services-business-form-textarea{
  width: 100%;
  padding: 0.75rem;
  background-color: #1D1D1B;
  color: white;
  border: 1px solid #DADADA;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.services-business-form-input::placeholder, .services-business-form-textarea::placeholder {
  color: gray;
}
.services-business-form-button{
  margin-top: 1rem;
  padding: 0.75rem;
  width: 100%;
  background-color: #1D1D1B66;
  color: white;
  border: 1px solid #DADADA;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  font-family: Stapel;
}
.services-business-form-button:hover{
  background-color: rgba(52, 52, 50, 0.8);
}
.apartment-container{
  background-color: #878787;
}
.house-header{
  color: #1D1D1B;
}
.house-container{
  background-color: #DADADA;
}
.add-services-header{
  color: #1D1D1B;
}
.add-services-container{
  background-color: #DADADA;
}
.container-info{
  display: flex;
  width: 100%;
}
.container-info h2{
  text-align: left;
  margin: 20px 55px;
}
.infoBlock{
  width: 80%;
  display: flex;
  flex-direction: column;
}
.infoBlock p{
  text-align: left;
  font-family: Stapel;
  font-size: 18px;
  line-height: 22.77px;
}
.services-container {
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.vertical-line {
  position: absolute;
  /* top: 5%; */
  /* bottom: 5%; */
  width: 2px;
  background-color: gray;
}

.vertical-line.left {
  left: 3.5%;
}

.vertical-line.right {
  right: 3.5%;
}

.section-middle {
  flex: 1;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.services-title {
  align-self: flex-start;
  font-size: 45px;
  margin: 0;
}


.info-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 9px 40px 0 44px ;
  flex-wrap: wrap;
}
.bisiness-container {
  background-color: #878787;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 9px 40px 0 44px ;
}


.center-text p {
  width: 350px;
}

.right-text {
  width: 350px;
}

.service-image {
  width: 370px;
  height: auto;
  margin-right: 100px;
}
.blackText p{
  color: #1D1D1B;
}
.divider {
  border: 1px solid white;
  margin-bottom: 10px;
}
.divider-white {
  border: 1px solid rgb(90, 90, 90);
  margin-bottom: 10px;
}

.services-section {
  width: 107.5%;
  margin-top: -20px;
}

.services-section h2 {
  text-align: left;
  margin: 20px 55px;
}

.service-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  padding: 20px;
  margin-bottom: 0;
  height: 150px;
}

.service-left{
  display: flex;
  flex-direction: column;
  text-align: left;
}
.service-left h3,
.service-left p {
  margin: 8px 5px 25px;
  color: rgb(196, 196, 196);
}

.service-center {
  flex: 1;
  color: rgb(196, 196, 196);
  text-align: left;
}

.service-right {
  font-size: 50px;
  font-weight: 100;
  cursor: pointer;
  color: rgb(196, 196, 196);
}

.horizontal-divider {
  border: 1px solid gray;
}

.social-icons-container {
  position: absolute;
  right: 1%;
  top: 15%;
  display: flex;
  flex-direction: column;
  gap: 55px;
}

.icon {
  font-size: 24px;
  transition: color 0.3s;
  cursor: pointer;
}

.icon:hover {
  color: var(--primary-color);
}


.serviceform-formcomt{
  width: 100%;
  background-color: rgb(44, 44, 44);
}


.serviceform-wrapper {
  width: 65%; 
  margin: 0 auto; 
  background-color: rgb(44, 44, 44); 
  padding: 2rem;
  color: white;
  box-sizing: border-box;
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  min-height: 45vh; 
}

/* Заголовок */
.serviceform-wrapper h2 {
  margin-bottom: 2rem;
  align-self: start;

}

/* Формы с двумя колонками */
.serviceform-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%; 
}

/* Левая колонка */
.serviceform-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Правая колонка */
.serviceform-right {
  flex: 1;
}

/* Поля ввода и текстовое поле */
.serviceform-input, .serviceform-textarea {
  width: 100%;
  padding: 0.75rem;
  background-color: rgba(73, 72, 72, 0.836); 
  color: white;
  border: none;
  border-bottom: 1px solid gray; 
  border-radius: 4px;
  box-sizing: border-box;
}

.serviceform-textarea {
  resize: none;
  height: 150px;
}

/* Плейсхолдеры */
.serviceform-input::placeholder, .serviceform-textarea::placeholder {
  color: gray;
}

/* Блок с соглашением */
.serviceform-agreement {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  width: 100%; 
}

/* Ссылка на политику */
.serviceform-policy-link {
  color: var(--primary-color);
  margin-left: 0.3rem;
  text-decoration: none;
}

/* Кнопка отправки */
.serviceform-button {
  margin-top: 1rem;
  padding: 0.75rem;
  width: 100%;
  background-color: rgba(135, 135, 135, 1); 
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.serviceform-button:hover {
  background-color: rgb(96, 96, 96); 
}
@media (max-width: 1100px) {
  .tab-content{
    flex-direction: column;
    align-items: center;
  }
  .tab-content p{
    margin-left: 0;
    padding: 10px ;
    font-size: 24px;
  }
}
@media (max-width: 768px) {
    .container-info{
      flex-direction: column;
      align-items: center;
    }
  .capital-works-title p{
    font-size: 20px;
  }
  .capital-works-btn{
    font-size: 14px;
  }
  .infoBlock{
    width: 100%;
  }
  .service-image{
    margin: 0;
    width: 270px;
    height: auto;
  }
  .services-container{
    padding: 25px !important;
  }
  .section-middle{
    width: 100%;
  }
  .services-business-form-header{
    font-size: 24px;
  }
  .services-business-form-underheader{
    padding: 14px;
    font-size: 20px;
  }
  .services-business-form-leftBlock{
    width: 100%;
    padding: 5px;
  }
  .services-business-form-mainInfo{
    flex-direction: column;
  }
  .services-business-form-mainInfo-rightBlock{
    flex-direction: column;
  }
  .services-business-form-mainInfo-rightBlock p{
    width: 100%;
    padding: 25px;
    font-size: 12px;
  }
  .services-business-form-application{
    flex-direction: column;
  }
  .header-block h1{
    font-size: 30px;
  }
  .center-text p{
    width: auto;
  }
  .tarif-header{
    font-size: 24px;
  }
  .service-item{
    padding: 15px;
    gap: normal;
  }
  .add-services-list{
    padding: 15px;
  }
  .tab-content{
    padding: 10px;
  }
}
@media (max-width: 670px) {
  .tarifs-section{
    flex-wrap: wrap;
  }
  .add-services-list{
    padding: 10px;
  }
}
@media (max-width: 430px) {
  .service-item-content-section{
    width: 100%;
  }
  .services-business-form{
    margin: 25px 0;
  }
  .transport-service-tab{
    padding: 5px;
  }
  .tab-content-image{
    width: 90%;
    border-radius: 8px;
  }
  .tab-content{
    padding: 5px;
  }
  .serviceform-container{
    flex-direction: column;
  }
  .serviceform-wrapper{
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .serviceform-formcomt{
    display: flex;
    justify-content: center;
    padding: 25px;

  }
  .tab-content p{
    font-size: 18px;
  }
}