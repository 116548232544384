@import url("../../assets/variables.css");
.about-company {
  background-color: rgba(29, 29, 27, 1);
  color: white;
  padding: 20px;
  padding-top: 110px;
}

/* Первый контейнер */
.first-container {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

/* Текстовая часть */
.text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-section h1 {
  margin-bottom: 20px;
  font-size: 40px;
}

.text-section p {
  margin-bottom: 20px;
  font-size: 24px;
}

/* Изображение */
.image-section {
  flex: 1;
  position: relative;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-caption {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 10px;
  color: white;
  font-size: 24px;
  border-radius: 5px;
  text-align: left;
  max-width: calc(100% - 20px);

}


/* Контейнер с командой */
.team-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1px;
  background-color: rgb(101, 101, 101);
}

.team-box {
  width: 497px;
  height: 335px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

/* Чёрный квадрат */
.black-box {
  background-color: black;
  color: white;
  justify-content: flex-end;
  text-align: left;
  padding: 20px;
  position: relative;
}

.black-box p {
  position: absolute;
  bottom: 45px;
  left: 40px;
  font-size: 40px;
  font-weight: bold;
}

/* Сотрудники */
.employee-box {
  background-color: rgb(88, 88, 88);
  padding: 10px;
}

.employee-name {
  align-self: flex-start;
  margin-left: 30px;
  margin-bottom: 10px;
  font-weight: 200;
  font-size: 22px;
  color: white;
}

.team-box img {
  width: 422px;
  height: 242px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 10px;
}

.employee-position {
  font-size: 22px;
  color: white;
  align-self: flex-start;
  margin-left: 30px;
}




.aboutcomp-formcomt{
  width: 100%;
  background-color: #1D1D1B;
}


.aboutcomp-wrapper {
  width: 65%;
  margin: 0 auto;
  background-color: #1D1D1B;
  padding: 2rem;
  color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 45vh;
}

/* Заголовок */
.aboutcomp-wrapper h2 {
  margin-bottom: 2rem;
  align-self: start;

}

/* Формы с двумя колонками */
.aboutcomp-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

/* Левая колонка */
.aboutcomp-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Правая колонка */
.aboutcomp-right {
  flex: 1;
}

/* Поля ввода и текстовое поле */
.aboutcomp-input, .aboutcomp-textarea {
  width: 100%;
  padding: 0.75rem;
  background-color: rgba(73, 72, 72, 0.836);
  color: white;
  border: none;
  border-bottom: 1px solid gray;
  border-radius: 4px;
  box-sizing: border-box;
}

.aboutcomp-textarea {
  resize: none;
  height: 150px;
}

/* Плейсхолдеры */
.aboutcomp-input::placeholder, .aboutcomp-textarea::placeholder {
  color: gray;
}

/* Блок с соглашением */
.aboutcomp-agreement {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  width: 100%;
}

/* Ссылка на политику */
.aboutcomp-policy-link {
  color: var(--primary-color);
  margin-left: 0.3rem;
  text-decoration: none;
}

/* Кнопка отправки */
.aboutcomp-button {
  margin-top: 1rem;
  padding: 0.75rem;
  width: 100%;
  background-color: rgba(135, 135, 135, 1);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.aboutcomp-button:hover {
  background-color: rgb(96, 96, 96);
}

@media (max-width: 1025px){
  .team-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1px;
    background-color: rgb(101, 101, 101);
  }
  .team-box {
    width: 334px;
    height: 335px;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  }
  .team-box img {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .black-box {
    background-color: black;
    color: white;
    justify-content: flex-end;
    text-align: left;
    padding: 20px;
    position: relative;
  }

  .black-box p {
    position: absolute;
    bottom: 45px;
    left: 40px;
    font-size: 40px;
    font-weight: bold;
  }

  /* Сотрудники */
  .employee-box {
    background-color: rgb(88, 88, 88);
    padding: 10px;
  }

  .employee-name {
    align-self: flex-start;
    margin-left: 30px;
    margin-bottom: 10px;
    font-weight: 200;
    font-size: 22px;
    color: white;
  }

  .employee-position {
    font-size: 22px;
    color: white;
    align-self: flex-start;
    margin-left: 30px;
  }
}


@media (max-width: 768px) {

  .first-container {
    flex-direction: column;
  }
  .first-container p{
    font-size: 12px;
  }
  .first-container h1{
    font-size: 20px;
  }
  .image-section {
    order: 2;
  }

  .text-section {
    order: 1;
    margin-bottom: 20px;
  }

  .team-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .image-caption{
    font-size: 14px;
  }
  .black-box {
    background-color: black;
    color: white;
    justify-content: center;
    text-align: center;
    padding: 120px;

  }
  .black-box p{
    font-size: 12px;
  }
  .team-box {
    width: 100%;
    height: auto;
  }
  .employee-name{
    font-size: 10px;
  }
  .employee-position{
    font-size: 10px;
  }
  .black-heading{
    font-size: 20px;
  }
  .squares-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .square{
    width: auto;
  }

  .aboutcomp-container {
    flex-direction: column;
  }

  .aboutcomp-wrapper {
    width: 100%;
    margin: 0 auto;
    background-color: #1D1D1B;
    padding: 2rem;
    color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 45vh;
  }

  .aboutcomp-input,
  .aboutcomp-textarea,
  .aboutcomp-button {
    width: 100%;
  }
}
