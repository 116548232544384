@import url("../../assets/variables.css");
.footer {
    background-color: #1D1D1B;
    color: white;
    padding: 2rem;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.footer-logo {
    position: absolute;
    left: 1.5rem;
}
.footer-header{
    width: 100%;
}
.footer-logo img {
    width: 140px;
    height: 154px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 50%;
    max-width: 1000px;
    text-align: left;
    margin: 1.5rem 0;
    margin-right: 260px;
}


.footer-column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-left: 4rem;
}



.footer-column p {
    margin: 0;
    color: #ccc;
    font-size: 1rem;
}
.footer-bottom a:-webkit-any-link {
    text-decoration: none;
}

.footer-phone-icons {
    text-align: right;
}

.footer-phone {
    color: var(--primary-color) !important;
    font-size: 30px ;
    font-weight: 400 !important;
    margin-bottom: 0.5rem !important;
    white-space: nowrap;
    margin-left: 810px;
}

.footer-icons {
    display: flex;
    gap: 1.3rem;
    margin-left: 740px;
}

.footer-icon {
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}

.footer-bottom {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    color: grey;
    font-size: 0.9rem;
}

.footer-copy {
    margin: 0;
}
@media (max-width: 1025px){
    .footer-phone {
        color: var(--primary-color) !important;
        font-size: 30px ;
        font-weight: 400 !important;
        margin-bottom: 0.5rem !important;
        white-space: nowrap;
        margin-left: 740px;
    }
}

@media (max-width: 1100px) {
    .footer {
        padding: 1rem;
    }
    .footer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .footer-logo {
        position: static;
        margin-bottom: 1rem;
    }
    .footer-logo img{
        margin-bottom: -186px;
    }

    .footer-phone-icons {
        text-align: right;
    }

    .footer-phone {
        font-size: 1.2rem;
        color: var(--primary-color) ;
        font-weight: 400 ;
        margin-bottom: 0.5rem;
        white-space: nowrap;
        margin-left: 400px;
    }

    .footer-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem;
        text-align: left;
        width: 80%;

    }

    .footer-column {
        flex: 1;
        margin: 0;
    }
    .footer-column p {
        margin: 0;
        color: #ccc;
        font-size: 18px;
    }

    .footer-icons {
        /* position: absolute; */
        bottom: 1rem;
        right: 1rem;
        margin-left: 560px;
    }
    .emailfooter{
        font-size: 0.7rem;
    }
}
@media (max-width: 1100px) {
    .footer-column p {
        margin: 0;
        color: #ccc;
        font-size: 18px;
    }
    .footer-logo img{
        width: 92px;
        height: 105px;
        margin-bottom: -18px;
    }
    .footer-phone {
        font-size: 1.1rem;
        color: var(--primary-color) ;
        font-weight: 400 ;
        margin-bottom: 0.5rem;
        white-space: nowrap;
        margin-left: 100px;
    }
    .footer-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        text-align: left;
        width: 100%;
        margin-right: 0;
    }
}
@media (max-width: 1100px){
    .footer-logo img{
        width: 72px;
        height: 77px;
    }
    .footer-column p {
        margin: 0;
        color: #ccc;
        font-size: 18px;
    }
    .footer-phone{
        font-size: 1rem;
        color: var(--primary-color) ;
        font-weight: 400 ;
        margin-bottom: 0.5rem;
        white-space: nowrap;
        margin-left: 70px;
    }

}
@media (max-width: 768px) {
    .footer p{
        font-size: 12px;
    }
    .footer-icons {
        /* position: absolute; */
        bottom: 1rem;
        right: 1rem;
        margin-left: 160px;
        width: 80px;
    }
}
