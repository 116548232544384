@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url("../../assets/variables.css");
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.327);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10000;
    transition: top 0.3s ease;
}
.clients-accordion{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: normal;

}
.menu-block{
    display: flex;
    align-items: center;
}
.navbar * {
    font-family: Stapel;
}
.menu{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* Логотип */
.logo {
    display: flex;
    align-items: center;
}

.logo-image {
    width: 80px;
    height: auto;
}

/* Ссылки */
.links {
    display: flex;
    align-items: center;
    gap: 15px;
}

.links p {
    color: #ffffff;
    transition: color 0.3s;
}

.links p:hover {
    color: var(--primary-color);
}

/* Переключатель языка */
.language-switcher {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 25px;
}

.accordion-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color: #fffbfb;
    outline: none;
}

.language-options {
    position: absolute;
    top: 100%;
    background: rgba(0, 0, 0, 0.327);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    z-index: 10;
    opacity: 0; /* Скрыто */
    transform: translateY(-10px); /* Начальное смещение */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Плавная анимация */
}
.language-options.open {
    opacity: 1; /* Видимость */
    transform: translateY(0); /* Нормальная позиция */
}
.clients-options{
    display: flex;
    flex-direction: column;
    position: absolute;
    top:100%;
    background: rgba(0, 0, 0, 0.327);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 5px;
    overflow: hidden;
    z-index: 10;
    opacity: 1; /* Начальная скрытость */
    transform: translateY(-10px); /* Смещение вверх */
    transition: opacity 0.3s ease, transform 1.3s ease;
}
.clients-accordion-button{
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    font-size: 24px;
    font-weight: 400;
    color: #fffbfb;
    outline: none;
}
.language-options button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    width: 100%;
    text-align: left;
    transition: background 0.3s;
}

.language-options button:hover {
    background-color: #474747;
}

/* Кнопка "Связаться" */
.contact-button {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s;
}

.contact-button:hover {
    background-color: #333;
}

/* Дополнительные стили для выравнивания */
.navbar-left, .navbar-right {
    display: flex;
    align-items: center;
}

/* Бургер-меню */
.burger-menu {
    display: none;
    font-size: 24px;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
}

.burger-menu:hover {
    color: #e9e9e9;
}

/* Скрытое меню для мобильных */


.menu a {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.menu a:hover {
    color: #e9e9e9;
}
.close-btn{
    display: none;
}
/* Адаптивные стили */
@media (max-width: 1300px) {
    .close-btn{
        display: block;
        position: absolute;
        top: 25px;
        width: auto;
        right: 20px;
        height: 25px;
    }
    .menu {
        flex-direction: column;
        align-items: flex-start;
        background: rgba(0, 0, 0, 0.9);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 40px;
        z-index: 9999;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        opacity: 0; /* Скрыто */
        pointer-events: none; /* Отключаем взаимодействие */
    }

    .menu.open {
        transform: translateY(0); /* Плавно появляется */
        opacity: 1; /* Полностью видно */
        pointer-events: auto; /* Включаем взаимодействие */
    }

    .menu.close {
        transform: translateY(-100%); /* Плавно уходит вверх */
        opacity: 0; /* Скрываем */
        pointer-events: none; /* Отключаем взаимодействие */
    }
    .links {
        display: none;
    }

    .burger-menu {
        display: block;
        margin-left: 10px;
    }

    .menu a {
        margin-left: 0;
        text-align: left;
        margin-bottom: 10px;
    }
    .navbar-right{
        justify-content: center;
    }
    .links{
        display: flex;
        flex-direction: column;
        margin-left: 0;
        align-items: normal;
    }
    .logo {
        margin-left: 10px;
    }
    .menu-block{
        display: flex;
        align-items: normal;
    }
    .language-options{
        right: -20px;
    }
    .clients-options{
        display: flex;
        flex-direction: column;
        position: relative;
        top: auto;
        margin-top: 10px;
        background: rgba(0, 0, 0, 0.327);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        overflow: hidden;
        z-index: 10;
    }
}
@media (max-width: 768px) {
    .links p {
        font-size: 20px;
    }
}
